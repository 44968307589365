import React from 'react'
import Helmet from 'react-helmet'

import Layout from "../../components/layout"
import BgImage from '../../components/bg-image'

const Rooms = () => (
  <Layout>
    <Helmet title="Pool" />
    <BgImage image="/assets/outdoor-living.jpg" className="top">
      <h1>Outdoor Rooms</h1>
    </BgImage>
    <section className="content">
      <p>
        Outdoor rooms are a great way to make your swimming pool more cozy and
        comfortable, especially when you have finished your swim and want to
        relax, or need to keep an eye on the kids. We are experienced builders
        of the following outdoor rooms and furnishings:
      </p>
      <ul>
        <li>Cabanas</li>
        <li>Pergolas</li>
        <li>Garden Studio</li>
        <li>Furniture</li>
      </ul>
    </section>
  </Layout>
)

export default Rooms
